@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #1D1F20;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}