@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700);
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #1D1F20;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}
.coming-soon {

    height:100%; 
    width:100%; 
    
    display:-webkit-flex; 
    
    display:flex; 
    -webkit-justify-content:center; 
            justify-content:center; 
    -webkit-align-items:center; 
            align-items:center; 
    color:gainsboro;
    font-size: 100px;  
    text-decoration: none;
}

.coming-soon a {
    text-decoration: none;
}

/* media queries for iPhone devices */

@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .coming-soon{
        -webkit-justify-content: center;
                justify-content: center;
        font-size: 100px;

    }
}

/* media queries for iPad Retina devices */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    .coming-soon{
        -webkit-justify-content: center;
                justify-content: center;
        font-size: 100px;
    }


}
