@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');

.coming-soon {

    height:100%; 
    width:100%; 
    
    display:flex; 
    justify-content:center; 
    align-items:center; 
    color:gainsboro;
    font-size: 100px;  
    text-decoration: none;
}

.coming-soon a {
    text-decoration: none;
}

/* media queries for iPhone devices */

@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .coming-soon{
        justify-content: center;
        font-size: 100px;

    }
}

/* media queries for iPad Retina devices */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    .coming-soon{
        justify-content: center;
        font-size: 100px;
    }


}